export default defineNuxtRouteMiddleware((to, from) => {
  if (to.name === 'login-login_path') {
    const { context } = storeToRefs(usePortalStore())
    const sessionStore = useSessionStore()

    // If authentication is enabled
    // and a from.fullPath exists
    // and the path is not the login page
    // set the login return path to return the user to the previous page after login.
    if (context.value?.authentication_enabled === true && !!from?.fullPath && from.name !== 'login-login_path') {
      sessionStore.setLoginReturnPath(from.fullPath)
    }
  }
})
